@import '../common/color';

.salariosweb-volume {
  margin-top: 10px;
  margin-bottom: 10px;
  
  > .ant-row {
    justify-items: center;
    align-content: center;
    border: 1px solid @black-200;
    background: @white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 0px @shadow;
    border-radius: 6px;

    .ant-col {
      text-align: center;
      margin: 0px;
      padding: 0px;

      button.ant-btn.ant-btn-link.salariosweb-icon__button {
        min-height: 50px;
      }
    }
  }
}