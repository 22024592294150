@import '~antd/dist/antd.less';
@import './components/common/color';
@primary-color: #007BFF;
@secondary-color: #64616B;
@border-radius-base: 10px;
@font-size-base: 16px;

.App {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  scroll-behavior: smooth;

  .ant-layout-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    background-color: #F0F0F0;
    margin-top: 200px;
  }
  .ant-layout-content > .ant-row { 
    width: 100%;
  }
  
}

.ant-layout {
  background: #F0F0F0!important;
}
.ant-input-affix-wrapper-lg {
  padding: 15px 11px;
}
