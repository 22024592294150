@import '../common/color';

.salariosweb-text {
  width: 100%;
  display: inline-block;
}

.salariosweb-text__h1 { 
  font-size: 52px;
}

.salariosweb-text__h2 { 
  font-size: 40px;
}

.salariosweb-text__h3 { 
  font-size: 36px;
}

.salariosweb-text__h4 { 
  font-size: 32px;
}

.salariosweb-text__h5 { 
  font-size: 24px;
}

.salariosweb-text__h6 { 
  font-size: 20px;
}

.salariosweb-text__h7 { 
  font-size: 18px;
}

.salariosweb-text__h8 { 
  font-size: 16px;
}

.salariosweb-text__h9 { 
  font-size: 14px;
}

.salariosweb-text__h10 { 
  font-size: 12px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-secondary {
  color: @black-400;
}

.text-secondary {
  color: @black-400!important;
}