.avatar-picture {
  margin: 0 auto;
  width: 100%;
  text-align: center;

  > .ant-typography {
    text-align: left;
    display: flex;
    text-indent: 10px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 120px;
  }
}