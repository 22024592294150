@primary-700: #90278c;  
@success-700: #25CC00;
@error-700: #FF4F2B;
@info-700: #003590;
@info-600: #337EFF;
@black-700: #000B20;
@black-600: #484B52;
@black-500: #747A84;
@black-400: #A6B0C1;
@black-300: #CAD1DB;
@black-200: #EAEDF1;
@black-100: #F4F6F8;
@primary: @primary-700;
@success: @success-700;
@error: @error-700;
@black: @black-700;
@white: #FFFFFF;

@shadow: #0000000A;

.bg-primary, .bg-primary-700 {
  background-color: @primary;
}
.text-primary, .text-primary-700 {
  color: @primary;
}

.bg-success, .bg-success-700 {
  background-color: @success;
}
.text-success, .text-success-700 {
  color: @success;
}

.bg-error, .bg-error-700 {
  background-color: @error;
}
.text-error, .text-error-700 {
  color: @error;
}

.bg-info-700 {
  background-color: @info-700;
}
.text-info-700 {
  color: @info-700;
}

.bg-info-600 {
  background-color: @info-600;
}
.text-info-600 {
  color: @info-600;
}

.bg-black, .bg-black-700 {
  background-color: @black-700;
}
.text-black, .text-black-700 {
  color: @black-700;
}

.bg-black-600 {
  background-color: @black-600;
}
.text-black-600 {
  color: @black-600;
}

.bg-black-500 {
  background-color: @black-500;
}
.text-black-500 {
  color: @black-500;
}

.bg-black-400 {
  background-color: @black-400;
}
.text-black-400 {
  color: @black-400;
}

.bg-black-300 {
  background-color: @black-300;
}
.text-black-300 {
  color: @black-300;
}

.bg-black-200 {
  background-color: @black-200;
}
.text-black-200 {
  color: @black-200;
}

.bg-black-100 {
  background-color: @black-100;
}
.text-black-100 {
  color: @black-100;
}